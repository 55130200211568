<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading" :rules="rules" ref="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Название" prop="pki.name">
              <el-input
                type="textarea"
                v-model="form.pki.name"
                :rows="6"/>
            </el-form-item>
            <el-form-item label="Режим назначения ответственных">
              <br>
              <el-switch v-model="form.pki.responsible"
                         inactive-color="#409EFF"
                         active-text="Ввод за себя"
                         inactive-text="Назначение группами"/>
            </el-form-item>
            <el-form-item
              label="Ответственные"
              v-show="form.pki.responsible">
              <el-select
                v-model="form.pki.operator_ids"
                multiple
                clearable
                style="width: 100%">
                <el-option
                  v-for="item in iogvs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-card v-show="!form.pki.responsible" style="margin-bottom: 10px">
              <div style="text-align: right">
                <label class="el-form-item__label">Ответственные</label>
                <el-button
                  @click="form.responsible.push({ operator_ids: [], ebosp_ids: [] })"
                  type="primary">
                  <i class="fas fa-plus"/>
                  Добавить группу
                </el-button>
              </div>
              <el-card v-for="(item, idx) in form.responsible" :key="idx" style="margin-top: 10px">
                <div style="text-align: right; margin-bottom: 5px">
                  <el-button @click="deleteResponsibleGroup(idx)" type="danger">
                    <i class="fas fa-minus"/>
                  </el-button>
                </div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <label class="el-form-item__label">Ответственные</label>
                    <el-select
                      v-model="item.operator_ids"
                      multiple
                      clearable
                      filterable
                      style="width: 100%">
                      <el-option
                        v-for="item in operators"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="12">
                    <label class="el-form-item__label">ИОГВ</label>
                    <el-select
                      v-model="item.ebosp_ids"
                      multiple
                      clearable
                      filterable
                      style="width: 100%">
                      <el-option
                        v-for="item in iogvs"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-button style="margin-top: 20px" @click="selectAllIogvs(item)">Выбрать всех</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-card>
            <el-form-item label="Расчет" prop="pki.rule">
              <br>
              <el-radio-group v-model="form.pki.rule" size="medium">
                <el-radio-button label="1">Формула</el-radio-button>
                <el-radio-button label="-1">Значение</el-radio-button>
                <el-radio-button label="0">Выбор из списка</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <div v-if="form.pki.rule == 1">
              <el-form-item
                label="Обозначение Параметров">
                <el-select
                  v-model="form.symbol"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  value-key="id"
                  :remote-method="symbolsSearch"
                  :loading="symbolLoading"
                  style="width: 90%">
                  <el-option
                    v-for="item in symbols"
                    :key="item.id"
                    :label="item.designatio"
                    :value="{ id: item.id }">
                    <span style="font-weight: bold">{{ item.designatio }} </span>
                    <span style="font-weight: bold">{{ item.code }} </span>
                    <span>{{ item.description }}</span>
                  </el-option>
                </el-select>
                <el-button @click="dialogVisible = true"><i class="fas fa-plus"/></el-button>
              </el-form-item>
              <el-form-item
                label="Зависит от">
                <el-select
                  v-model="form.masters"
                  multiple
                  filterable
                  remote
                  reserve-keyword
                  value-key="id"
                  :remote-method="pkiSearch"
                  :loading="pkiLoading"
                  style="width: 100%">
                  <el-option
                    v-for="item in pki"
                    :key="item.id"
                    :label="item.pki_id"
                    :value="{ id: item.id }">
                    <span style="font-weight: bold">{{ item.pki_id }} </span>
                    <span style="font-weight: bold">{{ item.code }} </span>
                    <span>{{ item.name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Формула">
                <el-input v-model="form.pki.formula"/>
              </el-form-item>
              <el-form-item label="Точность расчёта">
                <el-input v-model="form.pki.accuracy" type="number"/>
              </el-form-item>
            </div>
            <div v-if="form.pki.rule == -1">
              <el-form-item label="Поясняющий текст">
                <el-input v-model="form.pki.clarification"/>
              </el-form-item>
            </div>
            <div v-if="form.pki.rule == 0">
              <el-card style="margin-bottom: 10px">
                <div style="text-align: right">
                  <label class="el-form-item__label">Значения</label>
                  <el-button
                    @click="addRuleIo"
                    type="primary">
                    <i class="fas fa-plus"/>
                    Добавить значение
                  </el-button>
                </div>
                <el-card v-for="(item, idx) in form.rule_io" :key="idx" style="margin-top: 10px">
                  <div style="text-align: right; margin-bottom: 5px">
                    <el-button @click="deleteRuleIo(idx)" type="danger">
                      <i class="fas fa-minus"/>
                    </el-button>
                  </div>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        :prop="'rule_io.' + idx +'.value'"
                        :rules="{ required: true, message: 'Не заполнено обязательное поле', trigger: ['change', 'blur'] }">
                        <label class="el-form-item__label">Значение</label>
                        <el-input v-model="item.value"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        :prop="'rule_io.' + idx +'.rating'"
                        :rules="{ required: true, message: 'Не заполнено обязательное поле', trigger: ['change', 'blur'] }">
                        <label class="el-form-item__label">Балл</label>
                        <el-input v-model="item.rating" type="number"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-card>
              </el-card>
            </div>
            <div v-if="form.pki.rule != 0">
              <el-card style="margin-bottom: 10px">
                <div style="text-align: right">
                  <label class="el-form-item__label">Диапазоны</label>
                  <el-button
                    @click="addRuleRo"
                    type="primary">
                    <i class="fas fa-plus"/>
                    Добавить диапазон
                  </el-button>
                </div>
                <el-card v-for="(item, idx) in form.rule_ro" :key="idx" style="margin-top: 10px">
                  <div style="text-align: right; margin-bottom: 5px">
                    <el-button @click="deleteRuleRo(idx)" type="danger">
                      <i class="fas fa-minus"/>
                    </el-button>
                  </div>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <label class="el-form-item__label custom-label">Минимальное значение диапазона</label>
                    </el-col>
                    <el-col :span="8">
                      <label class="el-form-item__label custom-label">Максимальное значение диапазона</label>
                    </el-col>
                    <el-col :span="8">
                      <label class="el-form-item__label custom-label">Балл</label>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item
                        :prop="'rule_ro.' + idx +'.input_min'"
                        :rules="ruleInputMinMax(idx)">
                        <el-input v-model="item.input_min" type="number">
                          <el-switch
                            slot="prepend"
                            v-model="item.equals_min"
                            active-icon-class="fas fa-greater-than-equal"
                            inactive-icon-class="fas fa-greater-than"/>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        :prop="'rule_ro.' + idx +'.input_max'"
                        :rules="ruleInputMinMax(idx)">
                        <el-input v-model="item.input_max" type="number">
                          <el-switch
                            slot="prepend"
                            v-model="item.equals_max"
                            active-icon-class="fas fa-less-than-equal"
                            inactive-icon-class="fas fa-less-than"/>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        :prop="'rule_ro.' + idx +'.rating'"
                        :rules="{ required: true, message: 'Не заполнено обязательное поле', trigger: ['change', 'blur'] }">
                        <el-input v-model="item.rating" type="number"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-card>
              </el-card>
              <el-form-item label="Направленность показателя">
                <el-select
                  v-model="form.pki.target_reachable"
                  style="width: 100%"
                  clearable>
                  <el-option
                    label="Повышаемый"
                    :value="true"/>
                  <el-option
                    label="Понижаемый"
                    :value="false"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Присвоение балла по уровню достижения целевого значения">
                <br>
                <el-switch v-model="form.pki.rating_by_target"/>
              </el-form-item>
              <el-form-item label="Целевое значение">
                <el-input v-model="form.pki.target_value" type="number"/>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Индекс КПР" prop="pki.pki_id">
                  <el-input v-model="form.pki.pki_id"/>
                </el-form-item>
                <el-form-item label="Классификация" prop="pki.classification_id">
                  <el-select v-model="form.pki.classification_id" style="width: 100%">
                    <el-option
                      v-for="item in classificationList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="Код">
                  <el-input v-model="form.pki.code"/>
                </el-form-item>
                <el-form-item label="Отчетные периоды">
                  <el-select
                    v-model="form.period_view"
                    style="width: 100%"
                    multiple
                    clearable
                    value-key="id">
                    <el-option
                      v-for="item in periodView"
                      :key="item.id"
                      :label="item.title"
                      :value="{ id: item.id }">
                    </el-option>
                  </el-select>
                  <el-button style="margin-top: 20px" @click="selectAllPeriods">Выбрать все</el-button>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Действующий">
                  <br>
                  <el-switch v-model="form.pki.valid"/>
                </el-form-item>
                <el-form-item label="В чём измеряется">
                  <el-select v-model="form.pki.type" style="width: 100%">
                    <el-option
                      v-for="item in unitOfMeasurement"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Блок" v-if="isKP">
              <el-select v-model="form.pki.main_direction_activity_id" style="width: 100%">
                <el-option
                  v-for="item in mainDirectionActivity"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="!isCreate">
              <el-form-item label="Причина изменений" prop="pki_change_log.description">
                <el-input v-model="form.pki_change_log.description"/>
              </el-form-item>
              <el-form-item prop="pki_change_log.file_id">
                <file-upload v-if="!loading" v-model="form.pki_change_log.file_id" @delete="deleteFile"
                             @file-uploaded="fileUploaded" :limit="1"/>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockButton">
            {{ isCreate ? 'Создать' : 'Сохранить' }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
      title="Параметр"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form :model="symbolForm">
        <el-form-item label="Код">
          <el-input v-model="symbolForm.code"></el-input>
        </el-form-item>
        <el-form-item label="Описание">
          <el-input v-model="symbolForm.description" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Обозначение">
          <el-input v-model="symbolForm.designatio"></el-input>
        </el-form-item>
        <el-form-item label="В чём измеряется">
          <el-select
            v-model="symbolForm.type"
            clearable
            style="width: 100%">
            <el-option
              v-for="(item) in unitOfMeasurement"
              :key="item.id"
              :label="item.title"
              :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Обязательный">
          <el-checkbox v-model="symbolForm.required"/>
        </el-form-item>
        <el-form-item label="Минимум">
          <el-input v-model="symbolForm.min" type="number"></el-input>
        </el-form-item>
        <el-form-item label="Максимум">
          <el-input v-model="symbolForm.max" type="number"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveSymbol" :disabled="lockSymbolButton">Создать</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload/Files'
import {
  getUnitOfMeasurement,
  getPeriodView,
  getIogvDict,
  getSymbols,
  getPki,
  getMainDirectionActivity
} from '@/api/catalogs'
import { getPKIDetail, savePKI, saveSymbol } from '@/api/api'

const symbolDefaltForm = {
  id: null,
  description: null,
  code: null,
  designatio: null,
  min: null,
  max: null,
  type: null,
  required: false
}

export default {
  components: {
    FileUpload
  },
  data () {
    return {
      loading: false,
      symbolLoading: false,
      pkiLoading: false,
      isCreate: false,
      lockButton: false,
      lockSymbolButton: false,
      dialogVisible: false,
      isKP: false,
      symbolForm: {
        id: null,
        description: null,
        code: null,
        designatio: null,
        min: null,
        max: null,
        type: null,
        required: false
      },
      ruleRoObj: {
        equals_max: false,
        equals_min: false,
        input_max: null,
        input_min: null,
        rating: null
      },
      ruleIoObj: {
        value: null,
        rating: null
      },
      unitOfMeasurement: [],
      periodView: [],
      operators: [],
      iogvs: [],
      symbols: [],
      pki: [],
      mainDirectionActivity: [],
      classifications: [
        {
          id: 1,
          title: 'Общий'
        },
        {
          id: 2,
          title: 'Индивидуальный'
        },
        {
          id: 3,
          title: 'КПР КНД'
        },
        {
          id: 4,
          title: 'Контрольный показатель'
        }
      ],
      form: {
        pki: {
          id: null,
          name: null,
          responsible: null,
          pki_id: null,
          classification_id: null,
          valid: null,
          type: null,
          formula: null,
          rule: 1,
          target_value: null,
          target_reachable: null,
          rating_by_target: null,
          code: null,
          accuracy: 3,
          clarification: null,
          nso_code: null,
          nso_pki_id: null,
          main_direction_activity_id: null,
          comment_only: null,
          torisUser_ids: [],
          operator_ids: [],
          classification: {
            id: null,
            name: null,
            description: null,
            few_name: null
          },
          mainDirectionActivity: null,
          unitOfMeasurement: {
            id: null,
            short_name: null,
            full_name: null
          }
        },
        pki_change_log: {
          description: '',
          file_id: null
        },
        period_view: [],
        responsible: [],
        rule_ro: [],
        rule_io: [],
        masters: [],
        slaves: [],
        symbol: []
      },
      rules: {
        'pki.rule': [
          {
            validator: (rule, value, callback) => {
              if (value === null) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        'pki.classification_id': [
          {
            validator: (rule, value, callback) => {
              if (value === null) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        'pki_change_log.description': [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        'pki.pki_id': [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        'pki.name': [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        'pki_change_log.file_id': [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'change'
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.meta?.isKP) {
      this.isKP = true
    }
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    }
    this.fetchData()
  },
  computed: {
    classificationList () {
      const classifications = this.classifications
      if (this.isKP) {
        return classifications.filter(x => x.id === 4)
      }
      return classifications.filter(x => x.id !== 4)
    }
  },
  methods: {
    selectAllPeriods () {
      this.form.period_view = this.periodView
    },
    selectAllIogvs (model) {
      model.ebosp_ids = this.iogvs.map(x => x.id)
    },
    fetchData () {
      this.loading = true
      getUnitOfMeasurement().then(response => {
        this.unitOfMeasurement = response.data.data
      })
      getIogvDict({ [this.isKP ? 'ebosp_on_kp' : 'ebosp_on_pki']: true }).then(response => {
        this.iogvs = response.data.data
      })
      getIogvDict({ [this.isKP ? 'operator_on_kp' : 'operator_on_pki']: true }).then(response => {
        this.operators = response.data.data
      })
      getMainDirectionActivity().then(response => {
        this.mainDirectionActivity = response.data.data
      })
      getPeriodView({ is_monthly: this.isKP }).then(response => {
        this.periodView = response.data.data
      })
      if (!this.isCreate) {
        getPKIDetail(this.$route.params.id).then(response => {
          this.form = Object.assign(this.form, response.data)
          this.symbols = response.data.symbol
          this.pki = response.data.masters
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    ruleInputMinMax (idx) {
      return {
        validator: (rule, value, callback) => {
          const min = this.form.rule_ro[idx].input_min?.trim()
          const max = this.form.rule_ro[idx].input_max?.trim()
          if (min || max) {
            callback()
          } else {
            callback(new Error('Должен быть заполнен минимум или максимум'))
          }
        },
        trigger: ['change', 'blur']
      }
    },
    deleteResponsibleGroup (idx) {
      this.form.responsible.splice(idx, 1)
    },
    deleteRuleRo (idx) {
      this.form.rule_ro.splice(idx, 1)
    },
    deleteRuleIo (idx) {
      this.form.rule_io.splice(idx, 1)
    },
    addRuleRo () {
      if (this.form.rule_ro.length < 5) {
        this.form.rule_ro.push(Object.assign({}, this.ruleRoObj))
      }
    },
    addRuleIo () {
      if (this.form.rule_io.length < 5) {
        this.form.rule_io.push(Object.assign({}, this.ruleIoObj))
      }
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockButton = true
          savePKI(this.form).then((response) => {
            this.$message({
              message: 'Успешно сохранено!',
              type: 'success'
            })
            this.$router.push({ name: '/control/pki/view', params: { id: response.data.pki.id } })
          }).finally(() => {
            this.lockButton = false
          })
        }
      })
    },
    saveSymbol () {
      this.lockSymbolButton = true
      saveSymbol(this.symbolForm).then((response) => {
        this.form.symbol.push(response.data)
        this.dialogVisible = false
        this.symbolForm = Object.assign({}, symbolDefaltForm)
      }).finally(() => {
        this.lockSymbolButton = false
      })
    },
    symbolsSearch (query) {
      if (query !== '') {
        this.symbolLoading = true
        getSymbols({ query }).then(response => {
          this.symbols = response.data.data
          this.symbolLoading = false
        })
      }
    },
    pkiSearch (query) {
      if (query !== '') {
        this.pkiLoading = true
        getPki({ query: query }).then(response => {
          this.pki = response.data.data
          this.pkiLoading = false
        })
      }
    },
    deleteFile () {
      this.form.pki_change_log.file_id = null
      this.$refs.form.validateField('pki_change_log.file_id')
    },
    fileUploaded (id) {
      this.form.pki_change_log.file_id = id
      this.$refs.form.validateField('pki_change_log.file_id')
    }
  }
}
</script>

<style scoped>
.custom-label {
  line-height: 1.2;
  word-break: break-word;
  margin-bottom: 5px;
  font-weight: 700;
  text-align: left;
}
.el-switch >>> .el-switch__label {
  color: #bbb;
}
.el-switch >>> .el-switch__label.is-active {
  color: #007bff;
}
</style>
